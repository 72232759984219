/*
 * @Description: 公共模块
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-09 11:32:46
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-09 19:42:58
 */
export let publicData = [{
        name: "型号",
        value: "model",
        type: "input"
    },
    {
        name: "额定转速(RPM)",
        value: "ratedSpeed",
        type: "input"
    },
    {
        name: "电机级数",
        value: "series",
        type: "input"
    },
    {
        name: "驱动方式",
        value: "driveWay",
        type: "select",
        options: [{
                lable: "变频",
                value: 1
            },
            {
                lable: "定频",
                value: 2
            },
        ],
    },
    {
        name: "功率(kW)",
        value: "power",
        type: "input"
    },
    {
        name: "功率因数(%)",
        value: "powerFactor",
        type: "input"
    },
]