<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-10-27 10:50:53
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 15:44:45
-->
<template>
  <div class="EquipentPage">
    <div class="Main">
      <!-- 概览 -->
      <div class="overview">
        <div class="content">
          <div class="left">
            <div class="imgBox" @click.stop>
              <div class="img">
                <span
                  class="bg"
                  :style="{ background: deviceInfo.bgcolor, opacity: 0.2 }"
                >
                  <!-- <svg-icon :icon-class="iconName" class="svgstyle"></svg-icon> -->
                </span>
                <i
                  :class="'iconfont ' + deviceInfo.imgUrl"
                  :style="'color:' + deviceInfo.bgcolor"
                ></i>
              </div>
              <!-- 选择设备图标 -->
              <div class="setingImg" v-if="isEditImg">
                <i class="el-icon-close close" @click="closeImgF"></i>
                <div class="setSelectImg">
                  <select-icon
                    :isShowImg="false"
                    ref="selectIcon"
                    size="60px"
                    :color="color"
                    v-model="industryImgUrl"
                  ></select-icon>
                </div>
                <div class="setSelectColor">
                  <div class="lable font14" style="white-space: nowrap">
                    图标背景色
                  </div>
                  <div class="color_box">
                    <!-- <el-color-picker v-model="color"></el-color-picker> -->
                    <div
                      class="colorblock"
                      v-for="(item, index) in colorArr"
                      :key="index"
                      :style="'background-color:' + item"
                      @click="selectcolor(item, index)"
                    >
                      <i
                        class="el-icon-check"
                        v-show="coloractive == index"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 选择设备图标 end -->
            </div>
            <div class="describe">
              <div class="title" @click.stop>
                <div class="text">
                  <span v-show="!isEditName">{{ deviceInfo.deviceName }}</span>
                  <el-input
                    style="width: 236px"
                    v-show="isEditName"
                    v-model="deviceName"
                    placeholder="请输入内容"
                    size="small"
                  ></el-input>
                </div>

                <p class="code">{{ deviceInfo.onlyCode }}</p>
              </div>

              <div class="formbtnBox" v-if="isEditName">
                <el-button plain size="small" @click="isEditName = false"
                  >取消</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="closeF('deviceName')"
                  >确定</el-button
                >
              </div>

              <div class="deviceregion">
                <!-- 地址 -->
                <p>
                  <i class="el-icon-location-information"></i
                  ><span v-show="!isRegion">{{ deviceInfo.region }}</span>
                  <el-input
                    style="width: 312px"
                    @click.stop
                    v-show="isRegion"
                    v-model="region"
                    placeholder="请输入地址"
                    size="small"
                  ></el-input>
                </p>

                <!-- 地址 end -->
                <div class="formbtnBox" v-if="isRegion">
                  <el-button plain size="small" @click="isRegion = false"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    @click="closeF('region')"
                    >确定</el-button
                  >
                </div>
                <p>
                  {{ deviceInfo.nickName }}于{{
                    deviceInfo.createTime | time
                  }}创建
                </p>
                <p></p>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="qrCode" @click="qrCodeF">
              <img src="@/assets/images/qrcode.png" class="qrcode" alt="" />
            </div>
            <div class="group">
              <div class="lable">分组：</div>
              <div class="select">
                <span> {{ deviceInfo.groupName }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- remark -->
        <div class="remark">
          <el-input
            :disabled="remarkDisabled"
            type="textarea"
            :rows="2"
            placeholder=""
            v-model="textarea"
          >
          </el-input>
          <div class="formbtnBox" v-if="!remarkDisabled">
            <el-button plain size="small" @click.stop="cancelremarkF"
              >取消</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click.stop="closeF('remark')"
              >确定</el-button
            >
          </div>
        </div>
        <!-- remark end -->
        <!-- 统计 -->
        <div class="statistics">
          <div>
            <div class="order">
              工单统计
              <span @click="showWorkOrderF"
                >查看 <i class="el-icon-arrow-right"></i
              ></span>
            </div>
            <div class="today">
              今日完成<span>{{ deviceInfo.workOrderNum }}</span>
            </div>
          </div>
          <div class="echartBox">
            <div class="Echarts" id="Echarts"></div>
          </div>
        </div>
        <!-- 统计 end -->
      </div>
      <!-- 概览 end -->
      <!-- 弹窗 -->
      <dialogC
        :visible="showWorkOrder"
        @close="closeDialogF"
        :pathQuery="pathQuery"
      />
      <!-- 弹窗 end -->
      <!-- 设备信息 -->
      <info :pathQuery="pathQuery" :Info="addInfo" />
      <!-- 设备信息 end -->
      <!-- 人员与角色 -->
      <role
        :pathQuery="pathQuery"
        :userList="userArr"
        @update="getDeviceSurveyF"
      />
      <!-- 人员与角色 end -->
      <!-- 相关班组 -->
      <group />
      <!-- 相关班组 end -->
    </div>

    <!-- 二维码 -->
    <el-dialog
      :visible.sync="dialogVisible"
      class="erweima_box"
      width="425px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog_title">设备二维码</span>

      <div class="codeImg">
        <img :src="deviceInfo.deviceOrcode" alt />
      </div>
    </el-dialog>
    <!-- 二维码 end -->
  </div>
</template>

<script>
import moment from "moment";
var echarts = require("echarts");
import info from "./privateComponents/info";
import role from "./privateComponents/role";
import group from "./privateComponents/group";
import { getDeviceSurvey } from "@/api/equipmentmanagement.js";
import selectIcon from "@/components/public/DeviceSelectIconinfo"; //选择设备图标组件
import dialogC from "./privateComponents/showWorkOrder";
export default {
  name: "EquipentPage",
  components: { info, role, group, selectIcon, dialogC },
  filters: {
    time(time) {
      if (time) {
        return moment(time).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return "";
      }
    },
  },
  data() {
    return {
      textarea: "",
      industryImgUrl: "",
      addInfo: [],
      userArr: [],
      pathQuery: {},
      deviceInfo: {},
      showWorkOrder: false,
      remarkDisabled: true,
      isEditImg: false,
      coloractive: 0,
      color: "",
      colorArr: ["#18C873", "#00BDFB", "#C24BFF", "#FF9A16", "#FF7D7D"],
      isEditName: false,
      deviceName: "",

      workOrderSevenNum: [],
      // 地址
      isRegion: false,
      region: "",
      dialogVisible: false,
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.pathQuery = {
      ...this.$route.query,
    };
    this.getDeviceSurveyF();
  },

  destroyed() {},
  methods: {
    cancelremarkF() {
      this.textarea = this.deviceInfo.remark;
      this.remarkDisabled = true;
      console.log(this.remarkDisabled);
    },
    qrCodeF() {
      this.dialogVisible = true;
    },
    // 编辑地址
    regionF() {
      this.region = this.deviceInfo.region;
      this.isRegion = true;
    },
    // 编辑设备名称
    editNameF() {
      this.deviceName = this.deviceInfo.deviceName;
      this.isEditName = true;
    },
    // 编辑备注
    editRemarkF() {
      if (!this.isEditImg && !this.isEditName) {
        this.remarkDisabled = false;
      }
    },
    closeF(type) {
      let data = {};
      if (type == "remark") {
        data = { remark: this.textarea };
      }
      // 编辑头像
      if (type == "imgUrl") {
        data = { imgUrl: this.industryImgUrl, bgcolor: this.color };
      }
      if (type == "deviceName") {
        data = { deviceName: this.deviceName };
      }
      if (type == "region") {
        data = { region: this.region };
      }
      if (JSON.stringify(data) != "{}") {
        this.updateGroupInfoF(data, type);
      }
    },
    updateGroupInfoF(data, type) {
      let apiData = {
        enterpriseId: this.$store.state.enterpriseId, //企业id
        menuId: this.pathQuery.menuId, //菜单id
        typeId: this.pathQuery.id, //设备id
        deviceType: 1, //0设备  1项目
        ...data,
      };
      this.$api.equipmentmanagement.updateGroupInfo(apiData).then((res) => {
        if (this.ApiReply(res)) {
          if (type == "remark") {
            this.remarkDisabled = true;
            this.deviceInfo.remark = this.textarea;
          }
          if (type == "deviceName") {
            this.deviceInfo.deviceName = this.deviceName;
            this.isEditName = false;
          }
          if (type == "region") {
            this.deviceInfo.region = this.region;
            this.isRegion = false;
          }
          if (type == "imgUrl") {
            // 头像
          }
        }
      });
    },
    // 修改图标
    selectcolor(item, index) {
      //选择颜色
      this.coloractive = index;
      this.color = item;
    },
    editImgF() {
      this.isEditImg = true;
    },
    initCharts(bardata) {
      let myChart1 = echarts.init(document.getElementById("Echarts"));
      let option = {
        grid: {
          show: true,
          width: "340px",
          height: "74px",
          top: "0",
          left: "0",
          containLabel: false,
          backgroundColor: "#f1fcf7",
          borderWidth: 0,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          show: false, //不显示坐标轴线、坐标轴刻度线和坐标轴上的
          data: [...this.workOrderSevenNum[0]],
        },
        yAxis: {
          type: "value",
          show: false, //不显示坐标轴线、坐标轴刻度线和坐标轴上的
        },
        series: [
          {
            data: [...this.workOrderSevenNum[1]],
            type: "line",
            showSymbol: false,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: "#6be0a1",
                },
              },
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#cff4dd",
                },
                {
                  offset: 1,
                  color: "#f1fcf7",
                },
              ]),
            },
          },
        ],
      };
      myChart1.setOption(option);
    },

    closeImgF() {
      this.color = this.deviceInfo.bgcolor;
      this.industryImgUrl = this.deviceInfo.imgUrl;
      this.coloractive = this.colorArr.indexOf(this.color);
      this.isEditImg = false;
    },
    getDeviceSurveyF() {
      console.log("this.pathQuery", this.pathQuery);
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        menuId: 4, //菜单id
        typeId: this.pathQuery.qDeviceId, //设备id
        deviceType: 1, //0设备  1项目
      };
      getDeviceSurvey(data).then((res) => {
        if (this.ApiReply(res)) {
          this.deviceInfo = {
            ...res.data.data.deviceInfo,
          };
          this.addInfo = [...res.data.data.addInfo];
          this.userArr = res.data.data.user;
          console.log(">>>>>>>>>>>", res.data.data.user, this.userArr);
          this.color = this.deviceInfo.bgcolor;
          this.industryImgUrl = this.deviceInfo.imgUrl;
          this.textarea = this.deviceInfo.remark;
          this.coloractive = this.colorArr.indexOf(this.color);
          this.workOrderSevenNum = [...this.deviceInfo.workOrderSevenNum];

          this.initCharts();
        }
      });
    },
    // 工单统计查看
    closeDialogF(data) {
      this.showWorkOrder = data;
    },
    showWorkOrderF() {
      this.showWorkOrder = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.formbtnBox {
  padding: 10px 0;
  text-align: right;
}
.EquipentPage {
  background: #f7f7f7;
  min-height: 100%;
  display: flex;
  justify-content: center;
  .Main {
    width: 660px;
    display: flex;
    flex-direction: column;

    > div {
      background: #fff;
      margin-top: 12px;
    }
    // 概览
    .overview {
      padding: 30px;
      .content {
        display: flex;
        .left {
          display: flex;
          .imgBox {
            width: 74px;
            height: 74px;
            margin-right: 20px;
            position: relative;
            cursor: pointer;
            .btn {
              opacity: 0;
              position: absolute;
              left: 0;
              bottom: 0;
              color: #fff;
              background: rgba(0, 0, 0, 0.5);
              width: 100%;
              height: 30px;
              line-height: 30px;
              text-align: center;
              font-size: 12px;
              border-radius: 5px;
              z-index: 9;
              transition: all 0.2s;
            }
            &:hover {
              .btn {
                opacity: 1;
              }
            }
            .img {
              width: 74px;
              height: 74px;
              position: relative;
              border-radius: 50%;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              > i {
                font-size: 40px;
              }
              .bg {
                display: block;
                width: 74px;
                height: 74px;
                position: absolute;
                top: 0;
                left: 0;
              }
            }
            .setingImg {
              position: absolute;
              top: -30px;
              left: 100px;
              width: 298px;
              height: 303px;
              display: flex;
              flex-direction: column;
              background: #fff;
              box-shadow: 0 0 19px 0 rgba(19, 51, 95, 0.15);
              border-radius: 10px;
              z-index: 10;
              text-align: left;
              .close {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                &:hover {
                  color: $main_color;
                }
              }
              .setSelectColor {
                flex: 1;
                padding-left: 20px;
                .color_box {
                  display: flex;
                  padding-top: 10px;
                  .colorblock {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    margin-right: 15px;
                    i {
                      font-size: 16px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
          .describe {
            width: 340px;
            .title {
              width: 340px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .text {
                max-width: 50%;
              }
              span {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 40px;
              }
              .code {
                margin-left: 20px;
                box-sizing: content-box;
                padding: 0 10px;
                line-height: 26px;
                font-size: 13px;
                color: #18c873;
                background-color: #e8faf1;
                border: 1px solid #abeccd;
                border-radius: 3px;
                display: inline-block;
              }
            }
            > .deviceregion {
              text-align: left;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              p {
                margin-bottom: 10px;
              }
              p:nth-child(1) {
                margin: 0;
                min-height: 30px;
                align-items: center;
                display: flex;
                > i {
                  margin-right: 13px;
                }
              }
            }
          }
        }
        // right
        .right {
          flex: 1;
          .qrCode {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            img {
              width: 16px;
              height: 16px;
              cursor: pointer;
            }
          }
          .group {
            padding-top: 10px;
            display: flex;
            font-size: 14px;
            font-weight: 400;
            color: #56d79f;
            width: 100%;
            justify-content: flex-end;
            .lable {
              color: #999999;
              width: 42px;
            }
            .select {
              max-width: calc(100% - 45px);
              display: flex;
              align-items: center;
              justify-content: flex-end;
              span {
                display: inline-block;
                @extend .oneline;
                text-align: right;
              }
              i {
                transition: all 0.2s;
              }
              i.active {
                transform: rotate(180deg);
              }
              position: relative;
            }
          }
        }
      }
    }
    // 备注
    .remark {
      margin-top: 20px;
      /deep/.el-textarea.is-disabled .el-textarea__inner {
        background-color: #fff;
        border-color: #fff;
        color: #999999;
        resize: none;
        cursor: pointer;
      }
    }
    // 统计
    .statistics {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      height: 74px;
      > div:nth-child(1) {
        display: flex;
        height: 74px;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        span {
          margin-left: 10px;
        }
      }
      .order {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #606266;
        span {
          cursor: pointer;
          color: #56d79f;
        }
      }
      .today {
        font-size: 14px;
        color: #999999;
        span {
          font-size: 30px;
          font-family: DIN;
          font-weight: 500;
          color: #333333;
        }
      }
      .echartBox {
        width: 346px;
        height: 74px;
        border-radius: 5px;
        overflow: hidden;
        .Echarts {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
