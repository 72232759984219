/*
 * @Description: 通过设备大类获取对应的表单字段
 * @param {String} userGroup 用户分组
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-09 11:11:07
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-09 15:26:11
 */

export const getField = async function (userGroup) {
    const group = userGroup
    const file = await require(`./${group}`)
    const {
        data
    } = file
    return data
}