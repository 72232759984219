<!--
 * @Description: 添加角色
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-11-02 15:51:35
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 15:34:24
-->
<template>
  <div class="role">
    <div class="head">
      <div class="title">
        人员与角色
        <p v-show="userList.length != 0">{{ userList.length }}</p>
      </div>
    </div>
    <div class="notoData" v-show="userList.length == 0">
      <img src="/img/noteData.png" alt="" />
      <p>暂无人员</p>
    </div>
    <div class="list userList">
      <div class="item" v-for="(item, index) in userListComputed" :key="index">
        <div class="iteminfo">
          <div class="logo_box">
            <el-avatar :size="32" :src="'123png'">
              <span class="font10">{{ item.nickName | dealWithName }}</span>
            </el-avatar>
          </div>
          <div class="nickName">
            <div class="font14">{{ item.nickName }}</div>
          </div>
          <div class="tag" v-ellipsis:200>
            <div
              v-for="(item2, index2) in item.roleName.split(',')"
              :key="index2"
              :class="
                item2 == '负责人' ? 'orange' : item2 == '成员' ? 'green' : ''
              "
            >
              <i
                v-if="'负责人,成员'.indexOf(item2) != -1"
                class="iconfont"
                :class="item2 == '负责人' ? 'ql-iconbanzhang' : 'ql-iconzuyuan'"
              ></i>
              {{ item2 }}
            </div>
          </div>
        </div>
        <div class="font12 gray">{{ item.phone }}</div>
      </div>
    </div>
    <div
      class="showAll"
      v-show="userList.length > 5"
      @click="isShowAll = !isShowAll"
    >
      {{ isShowAll ? "收起" : "查看更多" }}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { getAuthorityRole } from "@/api/powercenter.js";
export default {
  name: "EquipentPagerole",
  components: {},

  props: {
    pathQuery: {},
    userList: {
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,

      selectArr: [],
      select: "",
      input: "",
      identityValue: "", //成员身份
      list: [], //人员列表
      timeF: null,
      searchInput: "",
      alreadySelect: [], //已经选择了的人员
      isShowAll: false,
    };
  },
  filters: {
    dealWithName(val) {
      let temp = val.split("");
      if (temp.length <= 1) {
        return temp.join("");
      } else {
        return temp[temp.length - 2] + temp[temp.length - 1];
      }
    },
  },
  computed: {
    userListComputed() {
      if (this.userList.length <= 5 || this.isShowAll) {
        return this.userList;
      } else {
        return this.userList.slice(0, 6);
      }
    },
  },
  //监听执行
  watch: {
    list: {
      handler: function () {
        this.alreadySelect = [...this.list];
      },
      deep: true, //深度监听设置为 true
    },
  },
  mounted() {
    this.getAuthorityRoleF();
  },
  methods: {
    getAuthorityRoleF() {
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      let data = {
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        menuId: 98, //菜单id
        status: 0, //0 不带所有成员   1带所有成员
        menuIdMold: "", //当前模块的menuId
        groupId: "", //分組id  沒有的传空串
      };
      getAuthorityRole(data).then((res) => {
        if (this.ApiReply(res)) {
          let list = [...res.data.data.list];
          for (let i = 0; i < list.length; i++) {
            if (list[i].menuId == 4) {
              this.selectArr.push(list[i]);
              if (list[i].isDefault == "yes") {
                this.identityValue = list[i].id;
              }
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.role {
  padding: 20px;
  .notoData {
    padding: 20px 0;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding-top: 20px;
    }
  }
  .showAll {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #aaaaaa;
    padding-top: 20px;
    cursor: pointer;
  }
  .userList {
    .item:nth-last-child(1) {
      &::after {
        display: none;
      }
    }
    .item {
      width: 100%;
      height: 74px;
      position: relative;
      &::after {
        content: "";
        width: calc(100% - 20px);
        height: 1px;
        background: #ececec;
        position: absolute;
        bottom: 0;
        left: 10px;
      }
      > .gray {
        color: #999999;
      }
      .iteminfo {
        display: flex;
        .logo_box,
        .nickName {
          display: flex;
          align-items: center;
        }
        .tag.ellipsis {
          padding-right: 10px;
          position: relative;
          &::after {
            content: "...";
            position: absolute;
            right: 0;
            background: #f7f7f7;
          }
        }
        .tag {
          display: flex;
          align-items: center;
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          i {
            font-size: 14px;
          }
          > div {
            white-space: nowrap;
            margin-right: 8px;
            order: 3;
          }
          .orange {
            color: #ff9000;
            order: 1;
          }
          .green {
            color: $main_color;
            order: 2;
          }
        }

        .nickName {
          margin-left: 18px;
          width: 88px;
          padding-right: 10px;
        }
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      position: relative;
      padding-left: 20px;
      display: flex;
      align-items: center;
      &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        border-left: 4px solid #56d79f;
        height: 16px;
      }
      p {
        margin-left: 10px;
        width: 30px;
        line-height: 18px;
        height: 18px;
        color: #999999;
        background-color: #efefef;
        border-radius: 9px;
        font-size: 12px;
      }
    }
    .btn {
      font-size: 14px;
      color: #56d79f;
      i {
        cursor: pointer;
      }
    }
  }
  .list {
    margin-top: 10px;
    background: #fafafa;
    padding: 0 10px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.el-avatar {
  background-color: #10aec2;
}
.font10 {
  transform: scale(0.9);
}
// 弹窗
.mask_box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  .dialog_box {
    width: 860px;
    height: 673px;
    margin: 15vh auto 0;
    padding: 28px;
    background-color: #ffffff;
    border-radius: 10px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .titles {
        position: relative;
        text-align: left;
        padding-left: 20px;
        font-size: 15px;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          border-left: 4px solid $main_color;
          height: 16px;
        }
      }
      .el-icon-close {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    .row1_left {
      display: flex;
      align-items: center;
      .text {
        margin-right: 15px;
        font-size: 14px;
        color: #999999;
      }
      .el-select {
        width: 119px;
      }
      .el-input {
        margin-left: 20px;
        width: 192px;
      }
    }
    .row1_right {
      display: flex;
      align-items: center;
      .text {
        margin-right: 30px;
        color: #56d79f;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
  .row2 {
    height: 480px;

    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 10px 20px;
      background-color: #f9f9f9;
    }
    .list_left {
      display: flex;
      align-items: center;
      .info {
        margin-left: 10px;
        text-align: left;
      }
      .gray {
        margin-top: 5px;
        color: #999999;
      }
    }
    .list_center {
      margin-left: 30px;
      font-size: 12px;
      .el-select {
        width: 135px;
      }
    }
    .list_right {
      width: 343px;
      > div {
        display: flex;
        align-items: center;
        font-size: 12px;
      }
      .tag {
        padding: 5px 10px;
        border-radius: 10px;
        color: #4cd99a;
        background-color: #e6f8f0;
      }
      .tag.remove {
        color: #fcbd51;
        background-color: #fbf6ec;
      }
      .svgstyle {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
      }
      .ml5 {
        margin-left: 5px;
      }
      &:hover .svgstyle {
        display: block !important;
      }
    }
    .list_end {
      cursor: pointer;
      font-size: 12px;
      color: #999999;
      i {
        font-size: 14px;
        margin-left: 20px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    .text {
      color: #56d79f;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>
