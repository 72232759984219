<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-10 11:56:18
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-21 15:00:31
-->
<template>
  <div class="DetailsUploadBtnComponents">
    <p v-if="fileName == ''">
      {{ item && item.length != 0 ? item[0].name : "" }}
    </p>
    <p v-else>{{ fileName }}</p>
    <el-upload
      v-if="(item.length == 0 || !item[0].name) && !disabled"
      ref="uploaddom"
      class="avatar-uploader"
      action
      accept=".zip,.com,.exe,.bin,.rar"
      :show-file-list="false"
      :http-request="upload"
      :on-change="handleChange"
      :before-upload="beforeAvatarUpload"
    >
      <el-button size="small" type="primary" ref="uploadBtn">上传</el-button>
    </el-upload>
    <el-button
      v-if="item.length != 0 && item[0].name && !disabled"
      size="small"
      type="primary"
      ref="uploadBtn"
      @click="deleteF"
      >删除</el-button
    >
  </div>
</template>

<script>
export default {
  name: "DetailsUploadBtnComponents",
  components: {},
  props: {
    item: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uploadS: false,
      fileName: "",
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {
    deleteF() {
      // 删除
      this.fileName = "";
      this.$emit("delete");
    },
    handleChange(file, fileList) {
      this.fileName = file.name;
      const formData = new FormData();
      const _file = file.raw;
      formData.append("file", _file);
      formData.append("name", this.fileName);
      this.$emit("update", formData);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      let type = true;
      return type && isLt2M;
    },
    upload(fileObj) {
      //上传
      if (this.uploadS == false) {
        this.uploadS = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.DetailsUploadBtnComponents {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
