<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-12 09:10:38
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-14 10:29:28
-->
<template>
  <div class="devienceInfo">
    <div class="head">
      <ul>
        <li :class="active == 0 ? 'active' : ''" @click="active = 0">
          设备信息
        </li>
        <li :class="active == 1 ? 'active' : ''" @click="active = 1">
          设备详情
        </li>
        <li
          class="line"
          :style="active == 1 ? 'transform: translateX(146px)' : ''"
        ></li>
      </ul>
    </div>
    <!--  -->
    <div class="content">
      <component :is="active == 0 ? 'info' : 'deviceDetails'"></component>
    </div>
    <!--  -->
  </div>
</template>

<script>
import info from "./Info";
import deviceDetails from "./Details";
export default {
  name: "devienceInfo",
  components: { info, deviceDetails },
  data() {
    return {
      active: 0,
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    if (this.$route.query.deviceId) {
      sessionStorage.setItem("deviceId", this.$route.query.deviceId);
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.devienceInfo {
  height: 100%;
  .head {
    ul {
      padding-top: 15px;
      padding-left: 30px;
      display: flex;
      position: relative;
      li {
        cursor: pointer;
        width: 96px;
        line-height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #303133;
      }
      li.active {
        color: #56d79f;
      }
      .line {
        width: 56px;
        line-height: 0px;
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        background-color: #56d79f;
        z-index: 2;
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transform: translateX(50px);
        list-style: none;
      }
    }
  }
  .content {
    height: calc(100% - 55px);
    background-color: #f9f9fb;
    padding-top: 10px;
  }
}
</style>
