/*
 * @Description: 设备详情-电机-技术参数数据文件
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-04 16:15:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-21 15:38:24
 */
export let data = [{
        name: "额定电压(V)",
        value: "ratedVoltage",
        type: "input",
    },
    {
        name: "额定电流(A)",
        value: "ratedCurrent",
        type: "input",
    },
    {
        name: "额定转速(RPM)",
        value: "ratedSpeed",
        type: "input",
    },
    {
        name: "额定功率(kW)",
        value: "ratedPower",
        type: "input",
    },
    {
        name: "功率因数(%)",
        value: "powerFactor",
        type: "input",
    },
    {
        name: "电机级数",
        value: "series",
        type: "input",
    },
    {
        name: "驱动方式",
        value: "driveWay",
        type: "select",
        options: [{
                lable: "变频",
                value: 1
            },
            {
                lable: "定频",
                value: 2
            }
        ]
    },
    {
        name: "绕组类型",
        value: "connection",
        type: "select",
        options: [{
                lable: "星形",
                value: 1
            },
            {
                lable: "三角形",
                value: 2
            }
        ]
    },
    {
        name: "驱动端轴承型号",
        value: "motorSeries",
        type: "input",
    },
    {
        name: "非驱动端轴承型号",
        value: "nonDriveEndBearingType",
        type: "input",
    },
    {
        name: "工作转速1(RMP)",
        value: "rpm1",
        type: "input"
    },
    {
        name: "工作转速2(RMP)",
        value: "rpm2",
        type: "input"
    },
    {
        name: "工作转速3(RMP)",
        value: "rpm3",
        type: "input"
    },
    {
        name: "工作转速4(RMP)",
        value: "rpm4",
        type: "input"
    },
    {
        name: "转向(从电机侧看)",
        value: "turn",
        type: "select",
        options: [{
                lable: "顺时针",
                value: 1
            },
            {
                lable: "逆时针",
                value: 2
            },
            {
                lable: "往复",
                value: 3
            },
        ],
    },
    {
        name: "联轴器类型",
        value: "coupling",
        type: "select",
        options: [{
                lable: "梅花联轴器",
                value: 1
            },
            {
                lable: "弹性柱联轴器",
                value: 2
            },
            {
                lable: "弹簧联轴器",
                value: 3
            },
            {
                lable: "万向节联轴器",
                value: 4
            },
            {
                lable: "刚性联轴器",
                value: 5
            },
            {
                lable: "膜式联轴器",
                value: 6
            },
        ],
    },
    {
        name: "备注",
        value: "remark",
        type: "input"
    },
]