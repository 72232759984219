/*
 * @Description: 设备详情-齿轮箱-技术参数数据文件
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-04 16:15:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-09 19:44:38
 */
import {
    publicData
} from "./publicData"
export let data = {
    //电机参数
    motor: publicData,
    //齿轮箱参数
    gearBoxInfo: [{
            name: "类型",
            value: "type",
            type: "select",
            options: [{
                    lable: "平行轴",
                    value: 1
                },
                {
                    lable: "垂直轴",
                    value: 2
                },
            ],
        },
        {
            name: "安装方式",
            value: "installation",
            type: "select",
            options: [{
                    lable: "卧式",
                    value: 1
                },
                {
                    lable: "立式",
                    value: 2
                },
            ],
        },
        {
            name: "输入转速",
            value: "inputSpeed",
            type: "input"
        },
        {
            name: "输出转速",
            value: "outputSpeed",
            type: "input"
        },
        {
            name: "传动级数",
            value: "driveSeries",
            type: "select",
            options: [{
                    lable: "1",
                    value: 1
                },
                {
                    lable: "2",
                    value: 2
                },
                {
                    lable: "3",
                    value: 3
                },
                {
                    lable: "4",
                    value: 4
                },
                {
                    lable: "5",
                    value: 5
                },
                {
                    lable: "6",
                    value: 6
                },
            ],
        },
        {
            name: "传动比",
            value: "transmissionRatio",
            type: "input"
        },
        {
            name: "运行温度",
            value: "operatingTemperature",
            type: "input"
        },
        {
            name: "齿轮类型",
            value: "gearType",
            type: "input"
        },
        {
            name: "驱动端轴承型号",
            value: "driveEndBearingType",
            type: "input",
        },
        {
            name: "非驱动端轴承型号",
            value: "nonDriveEndBearingType",
            type: "input",
        },
        {
            name: "备注",
            value: "remark",
            type: "input"
        },
    ],
}