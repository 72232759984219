<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-11-02 15:51:35
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-02 17:26:27
-->
<template>
  <div class="group">
    <div class="head">
      <div class="title">相关班组</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EquipentPagegroup",
  components: {},
  data() {
    return {};
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.group {
  padding: 20px;
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      position: relative;
      padding-left: 20px;
      &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        border-left: 4px solid #56d79f;
        height: 16px;
      }
    }
    .btn {
      font-size: 14px;
      color: #56d79f;
      i {
        cursor: pointer;
      }
    }
  }
}
</style>
