<!--
 * @Description: 编辑图片
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-20 17:49:31
 * @LastEditors: 支磊
 * @LastEditTime: 2021-11-22 16:40:34
-->
<template>
  <div class="DetailsSwiperImgComponents">
    <div class="model"></div>
    <div class="main">
      <i class="close el-icon el-icon-close" @click="closeF()"></i>
      <div class="head">图片处理</div>
      <div class="content">
        <div v-for="(item, index) in imgarr" :key="index" class="item">
          <i class="el-icon-delete delete" @click="deleteF(index)"></i>
          <img :src="item" alt="" class="item" />
        </div>
        <div v-if="imgarr.length != 9" class="addImg" @click="addImgF">
          <i class="el-icon-circle-plus-outline outline"></i>
        </div>
        <div v-for="item in 8 - imgarr.length" :key="'item' + item"></div>
      </div>
      <div class="footer">
        <el-button @click="closeF()" size="small" class="cancelBtn" plain
          >取消</el-button
        >
        <el-button size="small" type="primary" @click="finish">确认</el-button>
      </div>
    </div>
    <!-- 裁剪图片 -->
    <cropper
      v-if="isCropper"
      @close="cropperF"
      :imgUrl="imgUrl"
      @imgUrl="imgUrlF"
    />
    <!-- 裁剪图片 end -->
    <div style="display: none">
      <el-upload
        ref="uploaddom"
        class="avatar-uploader"
        action
        :show-file-list="false"
        :http-request="upload"
        :on-change="handleChange"
      >
        <el-button size="small" type="primary" ref="uploadBtn">上传</el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { uploadFile, updateDeviceImg } from "@/api/basics.js";
import cropper from "@/components/public/CropperComponents"; //裁剪图片
export default {
  name: "DetailsSwiperImgComponents",
  components: { cropper },
  props: {
    qDeviceId: {},
    imgArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isCropper: false,
      imgUrl: null,
      blobData: "",
      fileName: "",
      uploadS: false,
      imgarr: [],
    };
  },

  computed: {},
  //监听执行
  watch: {},
  mounted() {
    this.imgarr = [...this.imgArr];
  },
  methods: {
    uploadImgF(formData) {
      uploadFile(formData).then((res) => {
        if (this.ApiReply(res)) {
          console.log(res.data.data);
          this.imgarr.push(res.data.data);
        }
      });
    },
    deleteF(index) {
      // 删除图片
      this.imgarr.splice(index, 1);
    },
    finish() {
      // 提交
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      updateDeviceImg({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.applyId, //应用id
        hDeviceId: this.$route.query.deviceId, //霍尼韦尔设备id
        imgUrl: this.imgarr.join(","), //图片地址
      }).then((res) => {
        if (this.ApiReply(res)) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.closeF("update");
        }
      });
      console.log(123);
    },
    addImgF() {
      this.$refs.uploadBtn.$el.click();
    },
    closeF(type) {
      this.$emit("close", type);
    },
    imgUrlF(data) {
      this.blobData = data;
      let files = new window.File([this.blobData], this.fileName, {
        type: "image/png",
      });
      if (!this.beforeAvatarUpload(files)) {
        return;
      }
      this.imgUrl = URL.createObjectURL(data);
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      const formData = new FormData();
      formData.append("file", files);
      formData.append("qDeviceId", this.qDeviceId);

      formData.append("enterpriseId", WORK_APPLY.enterpriseId);
      formData.append("applyId", WORK_APPLY.applyId);
      this.uploadImgF(formData);
    },
    cropperF() {
      this.isCropper = false;
    },
    handleChange(file, fileList) {
      this.imgUrl = URL.createObjectURL(file.raw);

      this.fileName = file.name;
      this.isCropper = true;
    },
    upload(fileObj) {
      //上传
      if (this.uploadS == false) {
        this.uploadS = true;
        const formData = new FormData();
        const _file = fileObj.file;
        // if (!this.beforeAvatarUpload(_file)) {
        //   //验证是否符合标准
        //   this.uploadS = false;
        //   return;
        // }
      }
    },
    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";

      const isLt4M = file.size / 1024 / 1024 < 4;

      if (!isImg) {
        this.$message.error("上传的图片只能是png,jpg或jpeg格式");
      }
      if (!isLt4M) {
        this.$message.error("上传图片大小不能超过 4MB!");
      }

      return isImg && isLt4M;
    },
  },
};
</script>
<style lang="scss" scoped>
.DetailsSwiperImgComponents {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  .model {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #000;
  }
  .addImg {
    padding-top: 20px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    .outline {
      font-size: 36px;
      cursor: pointer;
      &:hover {
        color: $main_color;
      }
    }
  }

  .main {
    width: 860px;
    background: #fff;
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    padding: 28px;
    .close {
      color: #909399;
      font-size: 24px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      z-index: 9;
      &:hover {
        color: $main_color;
      }
    }
    .head {
      @extend .title_green;
      margin-bottom: 6px;
    }
    .content {
      margin-top: 20px;
      height: 600px;
      border-radius: 10px;
      background: #efefef;
      display: flex;
      align-items: flex-start;
      align-content: space-around;
      justify-content: space-around;
      flex-wrap: wrap;
      div {
        width: 30%;
        height: 180px;
        img {
          width: 100%;
          height: 180px;
        }
      }
      .item {
        position: relative;
        .delete {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 9;
          cursor: pointer;
          &:hover {
            color: $delete_color;
          }
        }
      }
      .addImg {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 10px;
        cursor: pointer;
        &:hover {
          i {
            color: $main_color;
          }
        }
        i {
          font-size: 40px;
          color: #aaa;
        }
      }
    }
    .footer {
      padding-top: 20px;
      text-align: right;
    }
  }
}
</style>
