<!--
 * @Description: 工单统计中工单详情
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-11-25 16:08:25
 * @LastEditors: 支磊
 * @LastEditTime: 2021-12-13 11:44:32
-->
<template>
  <div class="showWorkOrderContent">
    <div class="head">基本信息</div>
    <div class="basicInfo info">
      <div class="item" v-for="(item, index) in basicInfo" :key="index">
        <div class="lable">
          {{ item.name }}
        </div>
        <div
          class="content"
          :style="item.type == 'lineName' ? 'color: #56D79F;' : ''"
        >
          {{ basicInfoF(item.type) }}
        </div>
      </div>
    </div>
    <div class="head">资料</div>
    <div class="info">
      <div class="item" v-for="(item, index) in orderInfo" :key="index">
        <div class="lable">{{ item.formName }}</div>
        <div class="content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "showWorkOrderContent",
  components: {},
  props: {
    info: {},
  },
  data() {
    return {
      basicInfo: [
        { name: "工单名称：", type: "orderName" },
        { name: "相关任务：", type: "lineName" },
        { name: "提交人员：", type: "nickName" },
        { name: "提交时间：", type: "createTime" },
        { name: "提交班组：", type: "groupName" },
      ],
      orderInfo: [],
    };
  },

  computed: {},
  filters: {},
  //监听执行
  watch: {
    info: {
      handler() {
        this.orderInfo = JSON.parse(this.info.orderJson);
        console.log(this.orderInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.orderInfo = JSON.parse(this.info.orderJson);
    console.log(this.orderInfo);
  },
  methods: {
    basicInfoF(data) {
      if (data == "createTime") {
        return moment(this.info[data]).format("YYYY-MM-DD HH:mm:ss");
      } else if (data == "groupName") {
        return this.info[data] + `（ ${this.info.groupingName} ）`;
      } else {
        console.log(this.info, data);
        return this.info[data];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.showWorkOrderContent {
  padding: 20px;
  width: 100%;
  .head {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    padding-left: 14px;
    line-height: 1;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 16px;
      background: #18c873;
    }
  }
  .head,
  .item {
    margin-bottom: 20px;
  }
  .info {
    .item {
      line-height: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #696b6e;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .lable {
      }
      .content {
      }
    }
  }
}
</style>
