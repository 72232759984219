<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-11-02 15:51:35
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 15:31:11
-->
<template>
  <div class="info">
    <div class="head">
      <div class="title">设备信息</div>
      <div class="btn"></div>
    </div>
    <div class="notoData" v-if="addInfo.length == 0">
      <img src="/img/noteData.png" alt="" />
      <p>暂无信息</p>
    </div>
    <div class="list" v-if="addInfo.length != 0">
      <div class="item" v-for="(item, index) in addInfo" :key="index">
        <div class="label">
          <ellipsis :contentText="item.infoName">
            <template v-slot:end> : </template>
          </ellipsis>
        </div>
        <div class="content">{{ item.infoContent }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ellipsis from "@/components/public/ellipsis2";

export default {
  name: "EquipentPageinfo",
  components: { ellipsis },
  props: {
    pathQuery: {},
    Info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      addInfo: [],
    };
  },

  computed: {},
  //监听执行
  watch: {
    Info: {
      handler(newValue, oldValue) {
        this.addInfo = [...this.Info];
      },
      deep: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.info {
  padding: 20px;
  .notoData {
    padding: 20px 0;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      padding-top: 20px;
    }
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      position: relative;
      padding-left: 20px;
      &::after {
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        border-left: 4px solid #56d79f;
        height: 16px;
      }
    }
    .btn {
      font-size: 14px;
      color: #56d79f;
      i {
        cursor: pointer;
      }
    }
  }
  .list {
    .item {
      display: flex;
      min-height: 30px;
      line-height: 30px;
      justify-content: space-between;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      padding: 0 20px;
      transition: all 0.2s;
      background: #fff;
      cursor: pointer;
      &:hover {
        background: #f9f9f9;
      }
      .label {
        color: #3a3a3a;
        width: 140px;
        display: flex;
      }
      .content {
        color: #666666;
        padding-left: 20px;
        color: #666666;
        padding-left: 20px;
        word-break: break-all;
        text-align: left;
        display: inline-block;
        max-width: calc(100% - 140px);
      }
      position: relative;
      &:hover {
        .btnBox {
          right: -90px;
          z-index: 1;
        }
      }
      .btnBox {
        z-index: -1;
        padding-left: 20px;
        position: absolute;
        right: 0px;
        display: flex;
        transition: all 0.2s;
        > div {
          background: #fff;
          width: 26px;
          height: 26px;
          line-height: 26px;
          border-radius: 50%;
          text-align: center;
          color: #7e8083;
          font-size: 14px;
          margin-right: 6px;

          &:hover {
            color: #56d79f;
          }
        }
      }
    }
  }
}
</style>
