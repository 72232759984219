<!--
 * @Description:文本溢出隐藏，鼠标移入显示
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-03-03 09:46:55
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-11 15:10:44
-->
<template>
  <div class="Ellipsis" :style="ellipsisStyle" ref="Ellipsis">
    <el-tooltip
      class="item"
      effect="dark"
      :disabled="isElTooltipShow"
      :content="contentText"
      :placement="placement"
    >
      <div class="contentCls">
        <span ref="text">{{ contentText }}</span>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "Ellipsis",
  props: {
    placement: {
      // Tooltip 出现位置。位置汇总：top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end
      type: String,
      default: "top",
    },

    ellipsisStyle: { type: String, default: "" },
    contentText: {
      // 文本内容
      type: String,
      default: "",
    },
  },
  computed: {},
  data() {
    return {
      isElTooltipShow: true, // Tooltip 是否可用
    };
  },
  mounted() {
    this.ElTooltip();
  },
  methods: {
    ElTooltip() {
      this.$nextTick(function () {
        if (this.$refs.text.offsetWidth > this.$refs.Ellipsis.offsetWidth) {
          // 作比较：文本实际的宽度与外层壳的宽度
          this.isElTooltipShow = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.Ellipsis {
  width: 100%;
  display: flex;
  .contentCls {
    // 外层壳已做单行文本溢出显示省略号处理
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
}
</style>