/*
 * @Description: 设备详情-其他-技术参数数据文件
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-04 16:15:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-09 14:28:43
 */
import {
    publicData
} from "./publicData"
export let data = {
    //电机参数
    motor: publicData,
    //其他参数
    otherInfo: [{
            name: "驱动端轴承型号",
            value: "driveEndBearingType",
            type: "input"
        },
        {
            name: "非驱动端轴承型号",
            value: "nonDriveEndBearingType",
            type: "input"
        },
        {
            name: "备注",
            value: "remark",
            type: "input"
        },
    ]
}