/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2022-02-21 14:39:40
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 14:42:48
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/https'; // 导入http中创建的axios实例
import qs from 'qs'
export function getAuthorityRole(data) {
    return axios.post(`${base.baseApi}/authority/getAuthorityRole`, qs.stringify(data));
}