<!--
 * @Description: 设备详情
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-05-13 14:04:24
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 10:19:22
-->
<template>
  <div class="Details">
    <div class="main">
      <div class="Title">
        设备详情
        <div style="text-align: right">
          <el-button
            v-show="!is_img_edit"
            plain
            class="bianji"
            @click="operateImg(0)"
            size="small"
          >
            修改
            <i class="iconfont ql-iconbianji"></i>
          </el-button>
          <el-button
            v-show="is_img_edit"
            @click="operateImg(1)"
            size="small"
            class="cancelBtn"
            plain
            >取消</el-button
          >
          <!-- <el-button
            v-show="is_edit"
            @click="operateImg(2)"
            type="primary"
            size="small"
            >保存</el-button
          > -->
        </div>
      </div>
      <div class="content1">
        <div class="imgBox">
          <span
            v-if="is_img_edit"
            class="add el-icon-circle-plus-outline"
            @click="editImgF"
          >
          </span>
          <el-carousel
            class="swiper"
            trigger="click"
            height="100%"
            v-if="imgurlArr.length != 0"
          >
            <el-carousel-item v-for="(item, index) in imgurlArr" :key="index">
              <img :src="item" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div>
          <div class="head">
            <p>运行概况：</p>
            <span>实时报表></span>
          </div>
          <div class="content">
            <div>
              <p>
                {{ deviceOverview.detectionDays }}天{{
                  deviceOverview.detectionHours
                }}小时
              </p>
              <p class="lable">监测时长</p>
            </div>
            <div>
              <p class="red">{{ deviceOverview.historyAlarmNum }}次</p>
              <p class="lable">历史报警</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 报警历史统计 -->
      <!-- <div class="Title">报警历史统计</div>
      <div class="content2">
        <history> </history>
      </div> -->
      <!-- 报警历史统计 end -->
      <!--设备信息  -->
      <div class="Title">
        {{ deviceInfo.name }}
        <div style="text-align: right">
          <el-button
            v-show="!is_edit"
            plain
            class="bianji"
            @click="operate(0)"
            size="small"
          >
            修改
            <i class="iconfont ql-iconbianji"></i>
          </el-button>
          <el-button
            v-show="is_edit"
            @click="operate(1)"
            size="small"
            class="cancelBtn"
            plain
            >取消</el-button
          >
          <el-button
            v-show="is_edit"
            @click="operate(2)"
            type="primary"
            size="small"
            >保存</el-button
          >
        </div>
      </div>
      <div class="content">
        <ul>
          <li v-for="(item, index) in arr1" :key="index">
            <div class="lable">{{ item.name }}</div>
            <div v-if="item.type == 'input'">
              <el-input
                style="width: 200px"
                v-model="deviceInfo[item.content]"
                :placeholder="!is_edit ? '' : '请输入内容'"
                size="small"
                :disabled="!is_edit"
              ></el-input>
            </div>
            <!-- 选择设备分组 -->
            <div v-if="item.type == 'groupSelect'">
              <el-select
                v-model="deviceInfo[item.content]"
                :placeholder="!is_edit ? '' : '请选择'"
                :disabled="!is_edit"
                size="small"
                style="width: 200px"
              >
                <el-option
                  v-for="item in deviceTreeListOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 选择设备分组 end -->
            <!-- 选择 -->
            <div v-if="item.type == 'select'">
              <el-select
                v-model="deviceInfo[item.content]"
                :placeholder="!is_edit ? '' : '请选择'"
                size="small"
                style="width: 200px"
                :disabled="!is_edit"
                @input="inputF(item.content)"
              >
                <el-option
                  v-for="item in item.options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 选择 end -->
            <!-- 设备子类选择 -->
            <div v-if="item.type == 'subSelect'">
              <el-select
                v-model="deviceInfo[item.content]"
                :placeholder="!is_edit ? '' : '请选择'"
                size="small"
                style="width: 200px"
                :disabled="!is_edit"
              >
                <el-option
                  v-for="item in item.options[deviceInfo.type]"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <!-- 设备子类选择 end -->
            <!-- 上传附件 -->
            <div v-if="item.type == 'annex'" class="annex">
              <annex
                @delete="deleteAnnexF"
                @update="updateAnnexF"
                :item="deviceInfo[item.content]"
                :disabled="!is_edit"
              />
            </div>
            <!-- 上传附件 -->

            <div v-if="item.type == 'date'">
              <el-date-picker
                :disabled="!is_edit"
                style="width: 200px"
                v-model="deviceInfo[item.content]"
                type="date"
                size="small"
              >
              </el-date-picker>
            </div>
          </li>
        </ul>
      </div>
      <!-- 辊压机循环风机 end -->
      <!--技术参数  -->
      <specification
        ref="specification"
        :type="deviceInfo.type"
        :techParams="techParams"
        :disabled="is_edit"
      />
      <!-- 技术参数 end -->
    </div>

    <imgDialog
      :qDeviceId="qDeviceId"
      v-if="editSwiperImgS"
      @close="editSwiperCloseF"
      :imgArr="imgurlArr"
    />
  </div>
</template>

<script>
import {
  detailOverview,
  uploadPic,
  update,
  uploadAnnex,
} from "@/api/device.js";
import { tree } from "@/api/equipment.js";
import { getDeviceImg } from "@/api/basics.js";
import { detail } from "@/api/equipment.js";
import { info } from "./dataFile/deviceInfo";
import specification from "./private/DetailsSpecificationComponents"; //技术参数组件
import annex from "./private/DetailsUploadBtnComponents"; //上传附件
import imgDialog from "./private/DetailsSwiperImgComponents";

export default {
  name: "Details",
  components: { specification, annex, imgDialog },
  data() {
    return {
      editSwiperImgS: false, //编辑图片弹窗状态
      is_edit: false,
      is_img_edit: false, //编辑轮播图片状态
      deviceTreeListOptions: [], //设备分组列表
      arr1: info,
      deviceInfo: {},
      olddeviceInfo: {},
      imgUrl: "",
      imgUrled: null,
      deviceOverview: {},
      techParams: {}, //设备技术参数
      oldtechParams: {},
      annexfile: null,
      isAnnexfile: false,
      apiArr: [],
      apiNumber: 0,
      imgurlArr: [], //设备详情图
      qDeviceId: "",
    };
  },
  computed: {},
  //监听执行
  watch: {},
  mounted() {
    // this.getTreeDeviceF();
    this.getInfo();
    this.getDetailOverviewF();
    this.getDeviceImgF(); // 获取设备图片
  },
  methods: {
    // getTreeDeviceF() {
    //   tree().then((res) => {
    //     if (this.apiReply(res)) {
    //       this.deviceTreeListOptions = [...res.data.data[0].children];
    //     }
    //   });
    // },
    editSwiperCloseF(type) {
      this.editSwiperImgS = false;
      if (type == "update") {
        this.getDeviceImgF();
      }
    },
    getDeviceImgF() {
      // 获取设备图片
      let WORK_APPLY = JSON.parse(sessionStorage.getItem("WORK_APPLY"));
      getDeviceImg({
        enterpriseId: WORK_APPLY.enterpriseId, //企业id
        applyId: WORK_APPLY.applyId, //应用id
        hDeviceId: this.$route.query.deviceId, //霍尼韦尔设备id
      }).then((res) => {
        if (this.ApiReply(res)) {
          if (res.data.data.imgurl) {
            this.imgurlArr = res.data.data.imgurl.split(",");
          }
          this.qDeviceId = res.data.data.qDeviceId;
          this.is_img_edit = false;
        }
      });
    },

    inputF(type) {
      if (type == "type") {
        // 技术参数初始化
        this.$set(this.deviceInfo, "subType", "");
        Object.keys(this.techParams).forEach((key) => {
          this.$set(this.techParams, key, "");
        });
      }
    },
    deleteAnnexF() {
      // 删除附件
      this.isAnnexfile = true;
      this.$set(this.deviceInfo, "annexList", []);
    },
    updateAnnexF(data) {
      // 更新附件
      this.annexfile = data;
      this.isAnnexfile = true;
    },
    getDetailOverviewF() {
      detailOverview(this.$route.query.deviceId).then((res) => {
        if (this.apiReply(res)) {
          this.deviceOverview = {
            ...res.data.data.deviceOverview,
          };
        }
      });
    },

    operate(type) {
      this.apiArr = [];
      if (type == 0) {
        this.is_edit = true;
      }
      if (type == 1) {
        this.is_edit = false;
        this.imgUrl = this.imgUrled;
        this.deviceInfo = {
          ...this.olddeviceInfo,
        };
        this.techParams = {
          ...this.oldtechParams,
        };
        this.isAnnexfile = false;
      }
      if (type == 2) {
        // 提交修改
        let deviceInfo = {
          ...this.deviceInfo,
        };
        if (!deviceInfo.techParams) {
          deviceInfo.techParams = {};
        }
        deviceInfo.techParams[this.$refs.specification.key] = {
          ...this.$refs.specification.techParams,
        };

        if (this.isAnnexfile) {
          // 需要更新附件
          this.uploadAnnexF(deviceInfo);
        } else {
          deviceInfo.annexList = [];
          this.updateDataF(deviceInfo);
        }
      }
    },
    uploadAnnexF(deviceInfo) {
      if (!this.olddeviceInfo.annexList) {
        this.olddeviceInfo.annexList = [];
      }
      let annexList = [];

      if (this.annexfile) {
        uploadAnnex(this.annexfile).then((res) => {
          if (this.apiReply(res)) {
            annexList = [
              {
                id: res.data.data[0],
                operationId: 1,
                type: "file",
              },
            ];
            if (this.olddeviceInfo.annexList.length != 0) {
              // 更新附件
              annexList.unshift({
                id: this.olddeviceInfo.annexList[0].id,
                operationId: 2,
              });
            }
            deviceInfo.annexList = annexList;
            this.updateDataF(deviceInfo);
          }
        });
      }
      if (this.olddeviceInfo.annexList.length != 0 && !this.annexfile) {
        // 删除附件
        annexList = [
          {
            id: this.olddeviceInfo.annexList[0].id,
            operationId: 2,
          },
        ];
        deviceInfo.annexList = annexList;
        this.updateDataF(deviceInfo);
      }
    },
    operateImg(type) {
      if (type == 0) {
        // 编辑
        this.is_img_edit = true;
      }
      if (type == 1) {
        // 取消
        this.is_img_edit = false;
      }
    },
    updateDataF(deviceInfo) {
      let deptName = deviceInfo.deptName;
      for (let i = 0; i < this.deviceTreeListOptions.length; i++) {
        if (this.deviceTreeListOptions[i].name == deptName) {
          deviceInfo.deptId = this.deviceTreeListOptions[i].id;
          break;
        }
      }
      update(deviceInfo).then((res) => {
        if (this.apiReply(res)) {
          // 更新参数
          this.is_edit = false;
          this.annexfile = null;
          this.$message({
            message: "已修改",
            type: "success",
          });
          this.getInfo();
        }
      });
    },

    getInfo() {
      detail(this.$route.query.deviceId).then((res) => {
        if (this.apiReply(res)) {
          this.deviceInfo = {
            ...res.data.data.deviceInfo,
          };
          console.log(">>>>>>", this.deviceInfo);
          if (this.deviceInfo.techParams) {
            let obj = {};
            for (let key in this.deviceInfo.techParams) {
              Object.assign(obj, this.deviceInfo.techParams[key]);
            }
            this.techParams = { ...obj };
            this.oldtechParams = { ...obj };
          }

          let picList = [];
          if (this.deviceInfo?.picList) picList = this.deviceInfo?.picList;
          for (let i = 0; i < picList.length; i++) {
            if (picList[i].index == 1) {
              this.imgUrled = picList[i].picToken;
              this.imgUrl = picList[i].picToken;
              break;
            }
          }
          this.olddeviceInfo = { ...this.deviceInfo };
        }
      });
    },
    editImgF() {
      // 上传图片
      this.editSwiperImgS = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.annex {
  flex: 1;
}
.red {
  color: #ff0101 !important;
}

.Details {
  height: 100%;
  background: #fff;
  padding-bottom: 28px;
  overflow: auto;
  /deep/.el-input.is-disabled {
    .el-input__inner {
      border-color: #f8f9fa;
      color: #909399;
      background-color: #f8f9fa;
    }
    .el-input__suffix {
      display: none;
    }
  }
  .bianji {
    border: 0px solid #fff;
    font-size: 14px;

    i {
      margin-left: 8px;
      color: #18c873;
    }
  }
  .main {
    margin: 0 auto;
    width: 825px;
    .Title {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 24px;
      text-align: left;
      color: #1f2329;
      font-size: 16px;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      padding: 16px 0;
      padding-left: 20px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        border-left: 4px solid #56d79f;
        height: 16px;
        margin-top: -8px;
      }
    }
    .content1 {
      display: flex;
      justify-content: space-between;

      border-radius: 10px;
      > div {
        width: 49%;
        background: #f8f9fa;
      }
      > div:nth-child(2) {
        padding: 0 20px;
        .head {
          display: flex;
          height: 58px;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #313131;
          }
          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #56d79f;
          }
        }
        .content {
          display: flex;
          align-items: center;
          height: calc(100% - 58px);
          > div {
            width: 50%;
            p {
              font-size: 18px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #313131;
              margin-bottom: 25px;
            }
            p.lable {
              font-size: 14px;
              font-weight: 400;
              color: #8f959e;
            }
          }
        }
      }
      .imgBox {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 303px;
        .swiper {
          width: 100%;
          height: 100%;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
        &:hover {
          .add.hover {
            display: block;
            position: relative;
            z-index: 9;
          }
        }

        .add {
          font-size: 60px;
          color: #999;
          cursor: pointer;
          position: absolute;
          z-index: 9;
          &:hover {
            color: $main_color;
          }
        }
      }
    }
    .content {
      background: #f8f9fa;
      padding: 20px;
      padding-bottom: 0;
      border-radius: 10px;
      ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 49%;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #8f959e;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          .lable {
            width: 120px;
            color: #313131;
            margin-right: 10px;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
