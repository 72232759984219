/*
 * @Description: 
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2022-02-21 14:54:25
 * @LastEditors: 支磊
 * @LastEditTime: 2022-05-07 16:02:11
 */
import base from '@/config'; // 导入接口域名列表
import axios from '@/utils/https'; // 导入http中创建的axios实例
import qs from 'qs'
export function getDeviceSurvey(data) {
    data.free = "1"
    return axios.post(`${base.baseApi}/device/basics/getDeviceSurvey`, qs.stringify(data));
}
export function getDeviceWorkOrder(data) {
    return axios.post(`${base.baseApi}/device/basics/getDeviceWorkOrder`, qs.stringify(data));
}