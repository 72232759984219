/*
 * @Description: 设备基础信息
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-09 10:42:33
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 10:17:20
 */
export let info = [
    // {
    //     name: "组织架构",
    //     content: "deptName",
    //     type: "groupSelect",
    // },
    {
        name: "设备编码",
        content: "deviceCoding",
        type: "input",
    },
    {
        name: "设备大类",
        content: "type",
        type: "select",
        options: [{
                name: "电机类",
                value: 1
            },
            {
                name: "泵类",
                value: 2
            },
            {
                name: "压缩机",
                value: 3
            },
            {
                name: "风机",
                value: 4
            },
            {
                name: "齿轮箱",
                value: 5
            },
            {
                name: "其他",
                value: 0
            },
        ]
    },
    {
        name: "出厂日期",
        content: "productionTime",
        type: "date",
    },
    {
        name: "设备子类",
        content: "subType",
        type: "subSelect",
        options: [
            [{
                value: 0,
                name: "其他",
            }, ],
            [{
                    value: 11,
                    name: "异步电机",
                },
                {
                    value: 12,
                    name: "同步电机",
                },
                {
                    value: 13,
                    name: "发电机",
                },
                {
                    value: 14,
                    name: "汽轮机",
                },
                {
                    value: 10,
                    name: "其他",
                },
            ],

            [{
                    value: 21,
                    name: "离心泵",
                },
                {
                    value: 22,
                    name: "往复泵",
                },
                {
                    value: 23,
                    name: "真空泵",
                },
                {
                    value: 24,
                    name: "螺杆泵",
                },
                {
                    value: 25,
                    name: "齿轮泵",
                },
                {
                    value: 26,
                    name: "容积泵",
                },
                {
                    value: 20,
                    name: "其他",
                },
            ],

            [{
                    value: 31,
                    name: "离心式压缩机",
                },
                {
                    value: 32,
                    name: "往复式压缩机",
                },
                {
                    value: 33,
                    name: "螺杆压缩机",
                },
                {
                    value: 34,
                    name: "回转压缩机",
                },
                {
                    value: 30,
                    name: "其他",
                },
            ],
            [{
                    value: 41,
                    name: "离心式鼓风机",
                },
                {
                    value: 42,
                    name: "罗茨鼓风机",
                },
                {
                    value: 43,
                    name: "帕流式鼓风机",
                },
                {
                    value: 40,
                    name: "其他",
                },
            ],
            [{
                value: 51,
                name: "齿轮箱",
            }, ],


        ],
    },
    {
        name: "安装单位",
        content: "installManufacturer",
        type: "input",
    },
    {
        name: "型号",
        content: "model",
        type: "input",
    },
    {
        name: "安装位号",
        content: "installationTag",
        type: "input",
    },
    {
        name: "重要等级",
        content: "level",
        type: "select",
        options: [{
                name: "关键",
                value: 1
            },
            {
                name: "重要",
                value: 2
            },
            {
                name: "次要",
                value: 3
            },
            {
                name: "一般",
                value: 4
            },
        ]
    },
    {
        name: "投运日期",
        content: "runTime",
        type: "date",
    },
    {
        name: "生产厂家",
        content: "manufacturer",
        type: "input",
    },
    {
        name: "附件",
        content: "annexList",
        type: "annex",
    },
    {
        name: "备注",
        content: "remark",
        type: "input",
    },
]