<!--
 * @Description: 设备技术参数
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-09 15:49:31
 * @LastEditors: 支磊
 * @LastEditTime: 2021-09-26 18:29:22
-->
<template>
  <div class="DetailsSpecificationComponents">
    <!-- 无电机参数 -->
    <div v-for="(value, key, index) in obj" :key="index">
      <div class="Title">
        {{ keyNameObj[key] }}
      </div>
      <div class="content">
        <ul>
          <li v-for="(item, index) in value" :key="index">
            <div class="lable">{{ item.name }}</div>
            <div>
              <div v-if="item.type == 'input'">
                <el-input
                  v-model="techParams[item.value]"
                  placeholder=""
                  size="small"
                  style="width: 200px"
                  :disabled="!disabled"
                ></el-input>
              </div>
              <div v-if="item.type == 'select'">
                <el-select
                  v-model="techParams[item.value]"
                  :placeholder="!disabled ? '' : '请选择'"
                  size="small"
                  style="width: 200px"
                  :disabled="!disabled"
                >
                  <el-option
                    v-for="item2 in item.options"
                    :key="item2.value"
                    :label="item2.lable"
                    :value="item2.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 无电机参数 end -->
  </div>
</template>

<script>
import { getField } from "../deviceInfo/index";
export default {
  name: "DetailsSpecificationComponents",
  components: {},
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: -1,
    },
    techParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      input: "",
      arr: [],
      keyNameObj: {
        motor: "电机参数",
        compressorInfo: "压缩机参数",
        fanInfo: "风机参数",
        gearBoxInfo: "齿轮箱参数",
        otherInfo: "其他参数",
        pumpInfo: "泵参数",
      },
      obj: {},
      arrsName: ["pump", "other", "gearBox", "fan", "compressor"],
      dataObj: {
        // 根据这个数据选择是用哪个js数据文件
        1: {
          nameKey: "motor",
          name: "电机类",
        },
        3: {
          nameKey: "compressor",
          name: "压缩机",
        },

        4: {
          nameKey: "fan",
          name: "风机",
        }, //
        5: {
          nameKey: "gearBox",
          name: "齿轮箱",
        },
        0: {
          nameKey: "other",
          name: "其他",
        },
        2: {
          nameKey: "pump",
          name: "泵类",
        },
      },
    };
  },

  computed: {
    key() {
      return this.dataObj[this.type].nameKey;
    },
  },
  //监听执行
  watch: {
    type() {
      this.getFieldF(this.dataObj[this.type].nameKey);
    },
  },
  mounted() {},
  methods: {
    getFieldF(deviceType) {
      getField(deviceType).then((res) => {
        if (this.arrsName.indexOf(deviceType) == -1) {
          this.obj = { motor: [...res] };
        } else {
          this.obj = {
            ...res,
          };
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.DetailsSpecificationComponents {
  .Title {
    height: 68px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24px;
    text-align: left;
    color: #1f2329;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    padding: 16px 0;
    padding-left: 20px;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      border-left: 4px solid #56d79f;
      height: 16px;
      margin-top: -8px;
    }
  }
  .content {
    background: #f8f9fa;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 10px;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 49%;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #8f959e;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .lable {
          width: 120px;
          color: #313131;
          margin-right: 10px;
          text-align: left;
        }
      }
    }
  }
}
</style>
