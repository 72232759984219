<!--
 * @Description: 设备选择图标
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2020-09-10 16:29:05
 * @LastEditors: 支磊
 * @LastEditTime: 2021-05-20 16:20:44
-->
<template>
  <div class="img_Box">
    <div class="title">图标</div>
    <ul>
      <li
        :class="active == index ? 'active' : ''"
        v-for="(item, index) in imgArr"
        :key="index"
      >
        <div
          class="img1"
          @click.stop="clickActiveF(index, item)"
          :style="'border-color:' + color1"
        >
          <span :style="{ background: color1, opacity: 0.2 }">
            <!-- <svg-icon :icon-class="item" class="svgstyle"></svg-icon> -->
          </span>
          <i :class="'iconfont ' + item" :style="'color:' + color1"></i>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DeviceSelectIcon",
  components: {},
  props: {
    isShowImg: {
      //是否显示图片
      default: true,
    },
    size: {},
    color: {
      default: "#56d79f",
    },
    value: {
      default: "ql-iconfengshan",
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      iconName: "",
      color1: "#56d79f",
      popoverS: true,
      active: 0,
      imgArr: [
        "ql-iconfengshan",
        "ql-iconicon01",
        "ql-iconicon02",
        "ql-iconicon03",
        "ql-iconicon04",
        "ql-iconicon05",
        "ql-iconicon06",
        "ql-iconicon07",
        "ql-iconicon08",
        "ql-iconicon09",
        "ql-iconicon10",
        "ql-iconicon11",
      ],
    };
  },

  computed: {},
  //监听执行
  watch: {
    color() {
      this.color1 = this.color;
    },
    value() {
      this.selectF();
    },
  },
  mounted() {
    this.selectF();
    this.color1 = this.color;
    this.iconName = this.value;
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.popoverS = false; //点击其他区域关闭
      } else {
        this.popoverS = true;
      }
    });
  },
  methods: {
    selectF() {
      this.iconName = this.value;
      for (let i = 0; i < this.imgArr.length; i++) {
        if (this.imgArr[i] == this.iconName) {
          this.active = i;
          break;
        }
      }
    },
    close() {
      this.popoverS = false;
    },
    clickActiveF(index, item) {
      this.active = index;
      this.popoverS = false;
      this.iconName = item;
      this.$emit("input", item);
    },
  },
};
</script>
<style lang="scss" scoped>
.img_Box {
  width: 100%;
  padding: 12px;
  .title {
    font-size: 14px;
    padding-left: 8px;
    padding-bottom: 10px;
  }
}

ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    .img1 {
      position: relative;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 25px;
      }
      span {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .svgstyle {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  li.active {
    .img1 {
      width: 41px;
      height: 41px;
      padding: 2px;
      border: 1px solid $main_color;
    }
  }
}
</style>
