/*
 * @Description: 设备详情-风机-技术参数数据文件
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-04 16:15:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-09 14:29:24
 */
import {
    publicData
} from "./publicData"
export let data = {
    //电机参数
    motor: publicData,
    //风机参数
    fanInfo: [{
            name: "介质类型",
            value: "mediumType",
            type: "input"
        },
        {
            name: "风量(m3/h)",
            value: "airVolume",
            type: "input"
        },
        {
            name: "叶轮叶片数",
            value: "impellerBlades",
            type: "input"
        },
        {
            name: "风压范围(Pa)",
            value: "windPressureRange",
            type: "input"
        },
        {
            name: "驱动端轴承型号",
            value: "driveEndBearingType",
            type: "input"
        },
        {
            name: "非驱动端轴承型号",
            value: "nonDriveEndBearingType",
            type: "input"
        },
        {
            name: "备注",
            value: "remark",
            type: "input"
        },
    ],
}