<!--
 * @Description: 查看设备工单统计
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-11-25 09:43:57
 * @LastEditors: 支磊
 * @LastEditTime: 2022-02-21 15:24:58
-->
<template>
  <!-- visibleS -->
  <div class="gongdantongji" v-show="visible">
    <div class="gongdantongjiMain">
      <div class="header">
        <div class="flex_box">
          <div class="titles">工单统计</div>
        </div>
        <div class="flex_box">
          <i class="el-icon-close close" @click="visibleS = false"></i>
        </div>
      </div>
      <div class="Main">
        <div class="head">
          <el-date-picker
            @change="changeF"
            v-model="value"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </div>
        <div class="main">
          <div class="leftNavList">
            <!-- 筛选 -->
            <div>
              <div class="list_title">
                <el-input
                  placeholder="请输入内容"
                  v-model="name"
                  size="small"
                  clearable
                  @input="selectFile"
                >
                  <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
              </div>
            </div>
            <!-- 筛选 end -->
            <ul>
              <li
                v-for="(item, index) in listArr"
                :key="index"
                :class="{ active: index == activeIndex }"
              >
                <div>
                  <div class="logo">
                    <svg-icon
                      :icon-class="item.img"
                      class="svg_icon"
                    ></svg-icon>
                  </div>
                  <div>
                    <div class="orderName">{{ item.lineName }}</div>
                    <div class="name">{{ item.nickName }}</div>
                  </div>
                </div>
                <div>
                  <p class="group">{{ item.groupName }}</p>
                  <p class="time">{{ item.createTime | time }}</p>
                </div>
              </li>
            </ul>
            <div class="bottom">共{{ listArr.length }}条</div>
          </div>
          <div class="rightContent" v-if="listArr.length != 0">
            <infoC :info="listArr[activeIndex]" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeviceWorkOrder } from "@/api/equipmentmanagement.js";
import { formatDate } from "@/assets/js/untils";
import infoC from "./showWorkOrderContent";
import moment from "moment";
export default {
  name: "Departmentanduser",
  components: { infoC },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pathQuery: {},
  },
  filters: {
    time(time) {
      let date = new Date(time);
      return formatDate(date, "yy/MM/dd hh:mm:ss");
    },
  },
  data() {
    return {
      visibleS: false,
      value: "",
      name: "",
      startTime: "", //开始时间
      endTime: "", //结束时间
      listArr: [],
      activeIndex: 0,
      timeout: null,
    };
  },

  computed: {
    WORK_APPLY() {
      return JSON.parse(sessionStorage.getItem("WORK_APPLY"));
    },
  },
  //监听执行
  watch: {
    visible() {
      this.visibleS = this.visible;
      if (this.visible) {
        this.initTime();
      }
    },
    visibleS() {
      if (this.visibleS != this.visible) {
        this.$emit("close", this.visibleS);
      }
    },
  },
  mounted() {},
  methods: {
    initTime() {
      let endTime = new Date().getTime();
      let startTime = endTime - 7 * 24 * 60 * 60 * 1000;
      this.value = [
        moment(startTime).format("YYYY-MM-DD HH:mm:ss"),
        moment(endTime).format("YYYY-MM-DD HH:mm:ss"),
      ];
      this.changeF(this.value);
    },
    selectFile() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getDeviceWorkOrderF();
      }, 500);
    },
    changeF(data) {
      if (data) {
        this.startTime = data[0]; //开始时间
        this.endTime = data[1]; //结束时间
      } else {
        this.startTime = ""; //开始时间
        this.endTime = ""; //结束时间
      }
      this.getDeviceWorkOrderF();
    },
    getDeviceWorkOrderF() {
      let data = {
        enterpriseId: this.WORK_APPLY.enterpriseId, //企业id
        menuId: 4, //菜单id
        typeId: this.pathQuery.qDeviceId, //设备id
        deviceType: 1, //0设备  1项目
        ids: "", //传空串
        orderName: this.name, //工单名称
        startTime: this.startTime, //开始时间
        endTime: this.endTime, //结束时间
      };
      getDeviceWorkOrder(data).then((res) => {
        if (this.ApiReply(res)) {
          this.listArr = [...res.data.data];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.gongdantongji {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6) !important;
  margin-top: 0 !important;
  z-index: 99;
  .gongdantongjiMain {
    margin: 35px auto 0;
    padding: 28px;
    width: 974px;
    height: 810px;
    background-color: #ffffff;
    border-radius: 5px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .close {
        margin-left: 25px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
          color: $main_color;
        }
      }
    }
    .head {
      margin: 25px 0 20px;
    }
    .list_title {
      padding: 10px 15px;
      border-bottom: 1px solid #e9ecf4;
    }
  }
  .titles {
    position: relative;
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-left: 4px solid $main_color;
      height: 16px;
    }
  }
}
.Main {
  text-align: left;
  .main {
    height: 652px;
    border: 1px solid #e9ecf4;
    margin-top: 15px;
    display: flex;
    .leftNavList {
      width: 290px;
      height: 100%;
      background: #fafafa;
      border-right: 1px solid #e9ecf4;
      position: relative;
      padding-bottom: 34px;
      ul {
        li {
          display: flex;
          height: 60px;
          align-items: center;
          justify-content: space-between;
          padding: 0 17px;
          width: 100%;
          cursor: pointer;
          > div:nth-child(1) {
            display: flex;
            .orderName {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
            .name {
              font-size: 12px;
              color: #8f959f;
              margin-top: 5px;
            }
          }
          > div:nth-child(2) {
            text-align: right;
            > p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              line-height: 1;
            }
            p.time {
              font-size: 12px;
              margin-top: 5px;
            }
          }
          .logo {
            font-size: 20px;
            padding-right: 17px;
            .svg-icon {
              width: 27px;
              height: 34px;
            }
          }
        }
        li.active,
        li:hover {
          background: #f0f0f0;
        }
      }
      .bottom {
        height: 34px;
        line-height: 34px;
        padding-left: 10px;
        color: #8f959f;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        border-top: 1px solid #e9ecf4;
      }
    }
    .rightContent {
      width: calc(100% - 290px);
      height: 100%;
    }
  }
}
</style>
