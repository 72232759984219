/*
 * @Description: 设备详情-泵类-技术参数数据文件
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-04 16:15:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-06-09 14:28:31
 */
import {
    publicData
} from "./publicData"
export let data = {
    "motor": publicData,
    //泵参数
    "pumpInfo": [{
            name: "额定转速(RPM)",
            value: "ratedSpeed2",
            type: "input"
        },
        {
            name: "效率(%)",
            value: "efficiency",
            type: "input"
        },
        {
            name: "流量(m3/h)",
            value: "traffic",
            type: "input"
        },
        {
            name: "扬程(m)",
            value: "lift",
            type: "input"
        },
        {
            name: "有效气蚀量(m)",
            value: "effectiveCavitation",
            type: "input",
        },
        {
            name: "介质类型",
            value: "mediumType",
            type: "input"
        },
        {
            name: "介质比重(Kg/m3)",
            value: "mediumProportion",
            type: "input"
        },
        {
            name: "介质粘度(mm2/s)",
            value: "mediumViscosity",
            type: "input"
        },
        {
            name: "入口压力(Mpa)",
            value: "inletPressure",
            type: "input"
        },
        {
            name: "出口压力(Mpa)",
            value: "outletPressure",
            type: "input"
        },
        {
            name: "驱动端轴承型号",
            value: "driveEndBearingType",
            type: "input",
        },
        {
            name: "非驱动端轴承型号",
            value: "nonDriveEndBearingType",
            type: "input",
        },
        {
            name: "备注",
            value: "remark",
            type: "input"
        },
    ],
}