/*
 * @Description: 设备详情-压缩机-技术参数数据文件
 * @Version: 1.0
 * @Autor: 支磊
 * @Date: 2021-06-04 16:15:39
 * @LastEditors: 支磊
 * @LastEditTime: 2021-07-05 11:31:46
 */
import {
    publicData
} from "./publicData"
export let data = {
    //电机参数
    "motor": publicData,
    //压缩机参数
    "compressor": [{
            name: "效率(%)",
            value: "efficiency",
            type: "input"
        },
        {
            name: "流量(m3/h)",
            value: "traffic",
            type: "input"
        },
        {
            name: "入口压力(Mpa)",
            value: "inletPressure",
            type: "input"
        },
        {
            name: "出口压力(Mpa)",
            value: "outletPressure",
            type: "input"
        },
        {
            name: "驱动端轴承型号",
            value: "driveEndBearingType",
            type: "input",
        },
        {
            name: "非驱动端轴承型号",
            value: "nonDriveEndBearingType",
            type: "input",
        },
        {
            name: "备注",
            value: "remark",
            type: "input"
        },
    ],
}